import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import MaybeLogin from './components/MaybeLogin'
import { CenteredDiv, createWtxAuthInstance, WtxColors } from '@wavetronix/common-components'
import generateEnv from './env/generateEnv'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

if (window.location.pathname === '/logout') {
  window.localStorage.clear()
  window.location.href = window.location.origin
}

export const env = await generateEnv()

const root = ReactDOM.createRoot(document.getElementById('root'))

if (env.isError) {
  root.render(
    <CenteredDiv>
      <h3 style={{ color: WtxColors.INNOVATION_RED }}>{`${env.message}`}</h3>
    </CenteredDiv>
  )
} else {
  const msalInstance = await createWtxAuthInstance(env)
  const queryClient = new QueryClient()

  root.render(
    <QueryClientProvider client={queryClient}>
      <MaybeLogin msalInstance={msalInstance} />
    </QueryClientProvider>
  )
}
