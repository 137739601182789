import { Box, Stack, Avatar } from '@mui/material'
import { CenteredDiv } from '@wavetronix/common-components'
import dayjs from 'dayjs'
import FullCabinetLineGraph from './graphs/FullCabinetLineGraph'
import React, { useEffect, useState, useMemo } from 'react'
import DtsReportsApi from '../api/DtsReportsApi'
import { env } from '../index.js'
import { calculateColorBasedOnPercent } from '../utils/colorOptions.js'
import { CABINET_LINEOPTIONS } from '../components/graphs/FullCabinetLineGraph'

function ReportGraph({ barData }) {
  return (
    <div style={{ height: '200px' }}>
      <FullCabinetLineGraph barData={barData} customXAxis={true} showPercentLabels={false} />
    </div>
  )
}

function ReportBox({ report, previousReport, title }) {
  const [logExists, setLogExists] = useState(false)

  const reportPassPercent = useMemo(() => {
    if (report) {
      return ((report.results.filter(testResult => testResult.passed).length / report.results.length) * 100).toFixed(1)
    }
  }, [report])

  const failedTests = useMemo(() => {
    const getFailedTestNames = report => {
      if (report && report.results) {
        let failedTests = report.results.filter(test => test.passed === false)
        if (failedTests !== null) {
          let testNames = failedTests.map(test => test.testName).filter(name => name !== 'REMAINING TESTS CANCELLED')
          return testNames.sort()
        } else {
          return []
        }
      } else {
        return []
      }
    }

    if (report) {
      return getFailedTestNames(report)
        .map(rFail => {
          if (previousReport) {
            if (getFailedTestNames(previousReport).includes(rFail)) {
              return { name: rFail, isNewFailure: false }
            } else {
              return { name: rFail, isNewFailure: true }
            }
          } else {
            return { name: rFail, isNewFailure: true }
          }
        })
        .sort((a, b) => (a.isNewFailure === true ? -1 : 1))
    }
  }, [report, previousReport])

  useEffect(() => {
    const checkExists = async () => {
      if (report && report['id']) {
        const exists = await DtsReportsApi.logFileExists(report['id'])
        setLogExists(exists)
      }
    }

    checkExists().catch(error => console.log(error))
  }, [report, setLogExists])

  return (
    <div style={{ height: '160px', padding: '20px' }}>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <div className='branchTitle' style={{ color: `${CABINET_LINEOPTIONS[title].color}` }}>
          {`${CABINET_LINEOPTIONS[title].prettyName}`}
        </div>
        {report ? (
          <>
            <Box sx={{ flex: '1 1 auto' }} />
            <Stack sx={{ fontSize: '12px', marginRight: '10px' }}>
              {dayjs(report.startTime).format('MMM D, YYYY h:mm A')}
              <span style={{ fontFamily: 'Source Code Pro', float: 'right' }}>{`${report.machineName}`}</span>
            </Stack>
            <Box>
              <Avatar
                sx={{
                  backgroundColor: calculateColorBasedOnPercent(reportPassPercent).color,
                  fontSize: '15px',
                  color: 'black',
                  padding: '5px'
                }}
              >
                {reportPassPercent}
              </Avatar>
            </Box>
          </>
        ) : null}
      </Box>

      {!report ? (
        <div>
          <span style={{ fontSize: '25px', fontWeight: 'bold' }}>{'No Results'}</span>
        </div>
      ) : report.isAborted ? (
        <div style={{ margin: '20px 0px 30px 0px' }}>
          <div>
            <div className='testsTitle' style={{ color: 'Red' }}>{`Tests Aborted!`}</div>

            <div style={{ margin: '0px 0px 10px 0px' }}>{`Last Test Before Abort:`}</div>

            <div style={{ fontSize: '20px' }}>{report.lastTestBeforeAbort}</div>
          </div>
        </div>
      ) : (
        <Box sx={{ marginTop: '15px', overflow: 'auto', maxHeight: '120px' }}>
          {logExists
            ? failedTests.map((test, idx) => {
                return (
                  <div key={idx} className='testName'>
                    <a target={'_blank'} rel={'noreferrer'} href={`${env.urls.dtsURL}/logs/${report['id']}#${test.name}`}>
                      {test.name}
                    </a>
                    {test.isNewFailure === true ? (
                      <span style={{ color: 'red', fontWeight: 'bold', marginLeft: '5px' }}>{'*NEW*'}</span>
                    ) : (
                      ''
                    )}
                  </div>
                )
              })
            : failedTests.map((test, idx) => {
                return (
                  <div key={idx} className='testName'>
                    <span>{test.name}</span>
                    {test.isNewFailure === true ? (
                      <span style={{ color: 'red', fontWeight: 'bold', marginLeft: '5px' }}>{'*NEW*'}</span>
                    ) : (
                      ''
                    )}
                  </div>
                )
              })}
        </Box>
      )}
    </div>
  )
}

export default function ReportDisplay({ reportInfo }) {
  return (
    <Stack>
      <Box sx={{ background: 'white', borderRadius: '10px', padding: '10px' }}>
        <CenteredDiv>
          <h3 style={{ margin: '0px' }}>{reportInfo.title}</h3>
        </CenteredDiv>
      </Box>
      <Box sx={{ background: 'white', borderRadius: '10px', marginTop: '15px', padding: '10px', height: '100%' }}>
        {reportInfo.barData ? (
          <>
            <ReportGraph
              barData={reportInfo.barData}
              lanReport={reportInfo.lan}
              wanReport={reportInfo.wan}
              dualReport={reportInfo.dual}
              title={'All DATA'}
            />
            {reportInfo && reportInfo.reportInfo
              ? Object.entries(reportInfo.reportInfo).map(([key, info], index) => {
                  if (info && info.allReports.length > 0) {
                    return (
                      <div key={index}>
                        <hr />
                        <ReportBox report={info.report} previousReport={info.previousReport} title={key} />
                      </div>
                    )
                  } else {
                    return null
                  }
                })
              : null}
          </>
        ) : (
          <div>No results</div>
        )}
      </Box>
    </Stack>
  )
}
