import React from 'react'
import PageSwitcher from './PageSwitcher'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { SnackbarProvider } from 'notistack'

export default function NoLogin() {
  return (
    <SnackbarProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <PageSwitcher isLoginRequired={false} />
      </LocalizationProvider>
    </SnackbarProvider>
  )
}
