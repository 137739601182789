import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, LineChart, Line, LabelList } from 'recharts'
import { useMemo, useState } from 'react'
import { List, ListItem, Box, ListItemText } from '@mui/material'
import { ColorOptions } from '../../utils/colorOptions.js'

const COLOR_LIST = Object.values(ColorOptions)

export default function ProblemChildrenGraph({ barData, testTypes, showPercentLabels = false }) {
  const [tooltip, setToolTip] = useState()
  const [dataInfo, setDataInfo] = useState()

  const updateDataTooltipInfo = result => {
    setDataInfo(result)
  }

  const CustomTooltip = ({ active, payload, tooltip, testTypes }) => {
    const info = useMemo(() => {
      if (payload && payload.length > 0) {
        return payload[0].payload.problemChildren
          .map(type => ({
            name: type,
            percent: `${payload[0].payload[`${type} Percent Passed`]}%`,
            passed: payload[0].payload[`${type} Passed`],
            total: payload[0].payload[`${type} Passed`] + payload[0].payload[`${type} Failed`]
          }))
          .sort((a, b) => (a.percent > b.percent ? 1 : -1))
      }
    }, [payload])

    if (!active || !tooltip || !payload || !(payload.length > 0) || !payload[0].payload) return null

    updateDataTooltipInfo({
      info: info,
      problemChildrenCount: payload[0].payload.problemChildrenCount,
      option: payload[0].payload.option
    })
    return null
  }

  return (
    <>
      <ResponsiveContainer width='97%' height={600}>
        <LineChart
          width={500}
          height={600}
          data={barData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='option' />
          <YAxis domain={[0, 100]} />
          <Tooltip content={<CustomTooltip tooltip={tooltip} testTypes={testTypes} />} />
          {testTypes
            ? testTypes.map((testName, index) => (
                <Line
                  key={index}
                  type='monotone'
                  stroke={COLOR_LIST[index]}
                  dataKey={`${testName} Percent Passed`}
                  activeDot={{ r: 8 }}
                  onMouseOver={() => setToolTip('problemChildren')}
                >
                  {showPercentLabels === true ? <LabelList dataKey={`${testName} Percent Passed`} position='top' /> : <></>}
                </Line>
              ))
            : []}
        </LineChart>
      </ResponsiveContainer>
      {dataInfo ? (
        <Box sx={{ marginTop: '15px', overflow: 'auto', maxHeight: '400px', backgroundColor: 'white', padding: '15px' }}>
          <div>{dataInfo.option}</div>
          <List>
            {dataInfo.info
              ? dataInfo.info.map((info, index) => {
                  return (
                    <ListItem key={index} sx={{ color: COLOR_LIST[testTypes.indexOf(info.name)], padding: '3px' }}>
                      <ListItemText
                        primary={`${info.name}: ${info.percent}`}
                        secondary={`${info.passed} of ${info.total} passed`}
                      />
                    </ListItem>
                  )
                })
              : null}
          </List>
        </Box>
      ) : null}
    </>
  )
}
