import React, { useState, useEffect, useMemo } from 'react'
import { CenteredDiv, CustomSelect, CloseIconButton, SnackbarVariants } from '@wavetronix/common-components'
import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import DtsReportsApi from '../api/DtsReportsApi'
import dayjs from 'dayjs'
import StatusCardEx from './StatusCardEx'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { Switch, Toolbar, IconButton, Dialog, DialogContent, DialogTitle, Grid, Slide } from '@mui/material'
import version from '../env/version.json'
import LoadingPageMessage from './LoadingPageMessage'
import ConfigureLayouts from './ConfigureLayouts'
import { useCookies } from 'react-cookie'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { useSnackbar } from 'notistack'

const ReactGridLayout = WidthProvider(Responsive)
const checkForChangesIntervalInSeconds = 15 * 60

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const classes = {
  content: {
    backgroundColor: 'transparent',
    marginLeft: '8%',
    marginRight: '8%',
    marginTop: 20
  }
}

const ParseLayoutFromDB = layout => {
  return layout.map(function (info) {
    return { x: info.columnValue, y: info.rowValue, w: info.width, h: info.height, i: info.id }
  })
}

const ParseLayoutToDB = layout => {
  return layout.map(function (info) {
    return { columnValue: info.x, rowValue: info.y, width: info.w, height: info.h, id: info.i }
  })
}

const ParseBreakLayoutFromDB = layouts => {
  return {
    lg: ParseLayoutFromDB(layouts.largeLayout),
    md: ParseLayoutFromDB(layouts.mediumLayout),
    sm: ParseLayoutFromDB(layouts.smallLayout),
    xs: ParseLayoutFromDB(layouts.extSmallLayout)
  }
}

const ParseBreakLayoutToDB = layouts => {
  return {
    largeLayout: ParseLayoutToDB(layouts.lg),
    mediumLayout: ParseLayoutToDB(layouts.md),
    smallLayout: ParseLayoutToDB(layouts.sm),
    extSmallLayout: ParseLayoutToDB(layouts.xs),
    id: layouts.id
  }
}

export default function Overview({ isLoginRequired, reportsData, reportsDataLoading, reportsRefetch }) {
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar } = useSnackbar()
  const [cookies, setCookie] = useCookies(['layoutName'])
  const [refreshDate, setRefreshDate] = useState('')
  const [refreshTime, setRefreshTime] = useState('')
  const [cardLayout, setCardLayout] = useState({ lg: [], md: [], sm: [], xs: [] })
  const [layoutId, setLayoutId] = useState()
  const [enableEditGrid, setEditGrid] = useState(false)
  const [showLayoutOptions, setShowLayoutOptions] = useState(false)
  const [selectedLayout, setSelectedLayout] = useState('')
  const [editLayoutOptions, setEditLayoutOptions] = useState(false)
  const [newestLayout, setNewestLayout] = useState({})

  const {
    data: allLayouts,
    isLoading: allLayoutsLoading,
    refetch: layoutsRefetch
  } = useQuery({
    queryKey: ['allLayouts'],
    queryFn: async () => {
      return await DtsReportsApi.getCardLayouts()
    }
  })

  const { data: dbLayouts } = useQuery({
    queryKey: ['overviewLayout', selectedLayout],
    queryFn: async () => {
      let res = await DtsReportsApi.getCardLayout(selectedLayout)
      return res
    },
    enabled: !!selectedLayout && selectedLayout !== ''
  })

  const { data: changeId } = useQuery({
    queryKey: ['changeIdData'],
    queryFn: async () => await DtsReportsApi.hasChanges(isLoginRequired, instance, accounts),
    refetchInterval: checkForChangesIntervalInSeconds * 1000
  })

  const layoutOptions = useMemo(() => {
    if (allLayouts) {
      return allLayouts.map(layout => layout.id)
    }
  }, [allLayouts])

  useEffect(() => {
    if (!cookies) {
      setCookie('layoutName', 'InternalLayout', { path: '/' })
      setSelectedLayout('InternalLayout')
    } else if (cookies && (!cookies['layoutName'] || cookies['layoutName'] === '')) {
      setSelectedLayout('InternalLayout')
    } else {
      setSelectedLayout(cookies['layoutName'])
    }
  }, [cookies, selectedLayout, setCookie])

  useEffect(() => {
    function parseDBLayoutToReactLayout() {
      return ParseBreakLayoutFromDB(dbLayouts)
    }
    if (dbLayouts) {
      let watch = parseDBLayoutToReactLayout(dbLayouts)
      setCardLayout(watch)
      setLayoutId(dbLayouts.id)
    }
  }, [dbLayouts])

  useEffect(() => {
    reportsRefetch()
  }, [changeId, reportsRefetch])

  useEffect(() => {
    if (reportsData) {
      let currentTime = new Date()
      setRefreshDate(dayjs(currentTime).format('YYYY-MM-DD'))
      setRefreshTime(dayjs(currentTime).format('HH:mm:ss'))
    }
  }, [reportsData, changeId, setRefreshDate, setRefreshTime])

  document.getElementById('appTitle').innerHTML = `Overview`

  if (reportsDataLoading) {
    return <LoadingPageMessage message={'Loading product report overview data... Please wait.'} />
  }

  const onLayoutChange = (layout, layouts) => {
    let parsedLayouts = ParseBreakLayoutToDB({ ...layouts, id: layoutId })
    setNewestLayout(parsedLayouts)
  }

  const onCheckChangeEditMode = async e => {
    let checked = e.target.checked

    if (checked === true) {
      setEditGrid(true)
      setShowLayoutOptions(true)
    } else {
      setEditGrid(false)
      setShowLayoutOptions(false)
    }
  }

  return (
    <>
      <Dialog
        id='configureLayoutsDialog'
        fullScreen
        open={editLayoutOptions}
        onClose={() => setEditLayoutOptions(false)}
        sx={{ background: '#e0e1dd' }}
        TransitionComponent={Transition}
      >
        <DialogTitle
          sx={{
            backgroundColor: 'black',
            color: 'white',
            height: '64px',
            boxSizing: 'border-box'
          }}
        >
          <Grid container>
            <Grid item md={11} sm={11} xs={11}></Grid>
            <Grid item md={1} sm={1} xs={1}>
              <CloseIconButton
                id='configureLayoutsCloseButton'
                onClick={() => setEditLayoutOptions(false)}
                style={{ float: 'right' }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ background: '#e0e1dd' }}>
          <ConfigureLayouts
            allLayouts={allLayouts}
            allLayoutsLoading={allLayoutsLoading}
            reportsData={reportsData}
            layoutsRefetch={layoutsRefetch}
          />
        </DialogContent>
      </Dialog>
      <div style={classes.content}>
        <Toolbar />
        <div>
          <CenteredDiv>
            <span style={{ fontFamily: 'Proxima Nova', fontWeight: 'bold', fontSize: '18px' }}>Last Updated on </span>
            <span style={{ fontFamily: 'Source Code Pro', fontSize: '18px', marginLeft: '15px' }}>{refreshDate}</span>
            <span style={{ fontFamily: 'Source Code Pro', fontSize: '18px', marginLeft: '15px' }}>{refreshTime}</span>
          </CenteredDiv>
          <span
            style={{
              float: 'right',
              transform: 'translate(100%, -100%)'
            }}
          >{`v ${version.version}`}</span>
        </div>
        <ReactGridLayout
          container
          rowSpacing={5}
          rowHeight={200}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent='space-evenly'
          alignItems='center'
          isDraggable={enableEditGrid}
          isResizable={enableEditGrid}
          layouts={cardLayout}
          onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
          margin={[20, 40]}
          breakpoints={{ lg: 2400, md: 1800, sm: 1200, xs: 370 }}
          cols={{ lg: 4, md: 3, sm: 2, xs: 1 }}
        >
          {reportsData && dbLayouts
            ? reportsData.cabinetOverviews
                .filter(overview => dbLayouts.includedCabinets.includes(overview.id))
                .map(item => {
                  return (
                    <div key={item.id}>
                      <StatusCardEx statusItem={item} key={item.id} disableNavigation={enableEditGrid} />
                    </div>
                  )
                })
            : []}
        </ReactGridLayout>
        <div style={{ float: 'right', marginRight: '20px', marginBottom: '15px', display: 'flex' }}>
          {showLayoutOptions === true ? (
            <div>
              <IconButton
                id='cardLayoutSaveButton'
                onClick={async () => {
                  enqueueSnackbar('Saved new card layout.', SnackbarVariants.SUCCESS)

                  await DtsReportsApi.setCardLayouts({ ...dbLayouts, ...newestLayout })
                }}
              >
                <SaveIcon />
              </IconButton>
              <IconButton id='cardLayoutEditButton' onClick={() => setEditLayoutOptions(true)}>
                <EditIcon />
              </IconButton>
              <CustomSelect
                id='layoutSelect'
                style={{ marginRight: '15px', minWidth: '200px' }}
                label='Layout'
                value={selectedLayout}
                options={layoutOptions}
                onChange={e => {
                  setSelectedLayout(f => e.target.value)
                  setCookie('layoutName', e.target.value, { path: '/' })
                }}
              />
            </div>
          ) : (
            <span />
          )}
          <Switch id='editLayoutSwitch' color='primary' onChange={e => onCheckChangeEditMode(e)} />
          EDIT MODE
        </div>
      </div>
    </>
  )
}
