import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  InputAdornment,
  Toolbar,
  IconButton
} from '@mui/material'
import { useMemo, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import MetricsDisplay, { DEFAULT_TEST_FILTER } from './MetricsDisplay'
import LoadingPageMessage from './LoadingPageMessage'
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha'
import PercentIcon from '@mui/icons-material/Percent'
import { WtxColors } from '@wavetronix/common-components'

const drawerWidth = 300

export default function TestMetrics({ isLoginRequired, allTests, allTestsLoading }) {
  const [filter, setFilter] = useState(DEFAULT_TEST_FILTER)

  const [calculatingTestMap, setCalculatingTestMap] = useState(true)
  const [searchTest, setSearchTest] = useState('')
  const [selectedTest, setSelectedTest] = useState(null)
  const [sortOption, setSortOption] = useState('alphabetical')

  const testMap = useMemo(() => {
    let tMap = {}
    if (allTests) {
      tMap = allTests.reduce((map, obj) => {
        for (let test of obj.results) {
          if (test.testName in map) {
            map[test.testName].passed += test.passed === true ? 1 : 0
            map[test.testName].failed += test.passed === false ? 1 : 0
            map[test.testName].tests.push({
              reportId: obj.id,
              passed: test.passed,
              branch: obj.branch,
              startTime: obj.startTime,
              version: obj.version,
              testDevices: obj.testDevices,
              cabinetName: obj.cabinetName,
              connectionType: obj.connectionType,
              isHappyPath: obj.isHappyPath,
              isDC: obj.isDC,
              isLow: obj.isLow,
              machineName: obj.machineName
            })
          } else {
            map[test.testName] = {
              passed: test.passed === true ? 1 : 0,
              failed: test.passed === false ? 1 : 0,
              tests: [
                {
                  reportId: obj.id,
                  passed: test.passed,
                  branch: obj.branch,
                  startTime: obj.startTime,
                  version: obj.version,
                  testDevices: obj.testDevices,
                  cabinetName: obj.cabinetName,
                  connectionType: obj.connectionType,
                  isHappyPath: obj.isHappyPath,
                  isDC: obj.isDC,
                  isLow: obj.isLow,
                  machineName: obj.machineName
                }
              ]
            }
          }
        }
        return map
      }, {})
    }
    setCalculatingTestMap(false)
    return tMap
  }, [allTests])

  document.getElementById('appTitle').innerHTML = `Test Metrics`

  if (allTestsLoading) {
    return <LoadingPageMessage message={'Querying tests from database. Please wait.'} />
  }

  if (calculatingTestMap === true) {
    return <LoadingPageMessage message={'Anaylzing query results...'} />
  }

  return (
    <>
      <Toolbar />
      <Box sx={{ display: 'flex' }}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box'
            }
          }}
          variant='permanent'
          anchor='left'
        >
          <Toolbar />
          <TextField
            id='searchTestTextField'
            value={searchTest}
            onChange={e => setSearchTest(e.target.value)}
            size='small'
            sx={{ margin: '10px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ flex: '1 1 auto' }} />

            <IconButton id='alphabeticalSortButton' onClick={() => setSortOption('alphabetical')}>
              <SortByAlphaIcon sx={{ color: sortOption === 'alphabetical' ? WtxColors.IQ_BLUE : 'default' }} fontSize='small' />
            </IconButton>
            <IconButton id='percentSortButton' onClick={() => setSortOption('percent')}>
              <PercentIcon sx={{ color: sortOption === 'percent' ? WtxColors.IQ_BLUE : 'default' }} fontSize='small' />
            </IconButton>
          </Box>
          <List>
            {Object.keys(testMap)
              .sort((a, b) => {
                if (sortOption === 'alphabetical') {
                  return a.toLowerCase() > b.toLowerCase() ? 1 : -1
                } else {
                  let aFailRate = testMap[a].failed / (testMap[a].passed + testMap[a].failed)
                  let bFailRate = testMap[b].failed / (testMap[b].passed + testMap[b].failed)

                  return aFailRate < bFailRate ? 1 : -1
                }
              })
              .filter(testName => testName.toLowerCase().includes(searchTest.toLowerCase()))
              .map((testName, index) => (
                <ListItem key={testName} disablePadding>
                  <ListItemButton
                    id={`${testName}SelectButton`}
                    onClick={() => setSelectedTest({ testName: testName, results: testMap[testName].tests })}
                  >
                    <ListItemText
                      primary={testName}
                      secondary={`Fail Rate = ${(
                        (testMap[testName].failed / (testMap[testName].passed + testMap[testName].failed)) *
                        100
                      ).toFixed(2)}%`}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Drawer>
        <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
          {selectedTest ? (
            <MetricsDisplay selectedTest={selectedTest} filter={filter} setFilter={setFilter} />
          ) : (
            <Box
              sx={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginTop: '150px'
              }}
            >
              <h2>Select a test to view metrics.</h2>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}
