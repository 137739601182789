import { CircularProgress, Box } from '@mui/material'

export default function LoadingPageMessage({ message }) {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          marginTop: '150px'
        }}>
        <CircularProgress sx={{ justifyContent: 'center', alignItems: 'center' }} />
      </Box>
      <Box
        sx={{
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex'
        }}>
        <h2>{message}</h2>
      </Box>
    </>
  )
}
