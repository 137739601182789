import LoadingPageMessage from './LoadingPageMessage'
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  IconButton,
  TextField,
  Card,
  CardContent,
  CardActions
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { CenteredDiv, PrimaryButton, SnackbarVariants } from '@wavetronix/common-components'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import DtsReportsApi from '../api/DtsReportsApi'
import { useSnackbar } from 'notistack'

const DEFAULT_LAYOUT = {
  id: '',
  isCarousel: false,
  carouselPages: [],
  includedCabinets: [],
  largeLayout: [],
  mediumLayout: [],
  smallLayout: [],
  extSmallLayout: []
}

export default function ConfigureLayouts({ allLayouts, allLayoutsLoading, reportsData, layoutsRefetch }) {
  const { enqueueSnackbar } = useSnackbar()
  const [selectedLayout, setSelectedLayout] = useState(null)
  const [includeCabinetMap, setIncludeCabinetMap] = useState({})
  const [newLayoutName, setNewLayoutName] = useState('')

  useEffect(() => {
    if (selectedLayout && reportsData) {
      let map = reportsData.cabinetOverviews.reduce((map, obj) => {
        map[obj.id] = false
        return map
      }, {})
      for (let cabinetId of selectedLayout.includedCabinets) {
        map[cabinetId] = true
      }

      setIncludeCabinetMap(map)
    }
  }, [selectedLayout, reportsData])

  if (allLayoutsLoading) {
    return <LoadingPageMessage message={'Loading all layout configurations. Please wait...'} />
  }

  if (!reportsData) {
    return <LoadingPageMessage message={'Loading reports data. Please wait...'} />
  }

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item lg={3} xl={2}>
          <CenteredDiv>
            <h3 style={{ marginBottom: '0px' }}>Layouts</h3>
            <IconButton
              id='configureNewLayoutButton'
              sx={{ marginTop: '15px', marginLeft: '15px' }}
              onClick={() => {
                setSelectedLayout(DEFAULT_LAYOUT)
              }}
            >
              <AddIcon />
            </IconButton>
          </CenteredDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <CenteredDiv>
            <h3 style={{ marginBottom: '0px', marginTop: '27px' }}>Cabinets</h3>
          </CenteredDiv>
        </Grid>
        <Grid item lg={6} xl={8}>
          <CenteredDiv>
            <h3 style={{ marginBottom: '0px', marginTop: '27px' }}>Change Configuration</h3>
          </CenteredDiv>
        </Grid>
        <Grid item lg={3} xl={2}>
          <List>
            {allLayouts
              .sort((a, b) => (a.id > b.id ? 1 : -1))
              .map(layout => (
                <ListItem key={layout.id} disablePadding>
                  <ListItemButton
                    sx={{ borderRadius: '20px', boxShadow: '0px 0px 25px -10px rgba(0,0,0,0.9)', marginBottom: '15px' }}
                  >
                    <ListItemIcon
                      id={`${layout.id}LayoutListItemDeleteButton`}
                      onClick={async () => {
                        if (layout.id !== 'InternalLayout') {
                          await DtsReportsApi.deleteCardLayouts(layout.id).then(async () => await layoutsRefetch())
                          enqueueSnackbar('Successfully delete configuration.', SnackbarVariants.SUCCESS)
                          setSelectedLayout(null)
                        } else {
                          enqueueSnackbar('InternalLayout is the default layout and cannot be deleted.', SnackbarVariants.ERROR)
                        }
                      }}
                    >
                      <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText
                      id={`${layout.id}LayoutListItemSelectButton`}
                      primary={layout.id}
                      onClick={() => {
                        setSelectedLayout(layout)
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Grid>
        <Grid item lg={3} xl={2}>
          {selectedLayout ? (
            <List>
              {reportsData.cabinetOverviews
                .filter(cabinet => {
                  let show = includeCabinetMap[cabinet.id] === true
                  return !show
                })
                .sort((a, b) => (a.cabinetName > b.cabinetName ? 1 : -1))
                .map(cabinet => (
                  <ListItem key={cabinet.id} disablePadding>
                    <ListItemButton
                      sx={{ borderRadius: '20px', boxShadow: '0px 0px 25px -10px rgba(0,0,0,0.9)', marginBottom: '15px' }}
                    >
                      <ListItemIcon
                        id={`${cabinet.id}CabinetItemAddButton`}
                        onClick={() => {
                          setIncludeCabinetMap(map => ({ ...map, [cabinet.id]: true }))
                        }}
                      >
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText primary={cabinet.cabinetName} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          ) : (
            <CenteredDiv>Selected a layout to edit or create a new one.</CenteredDiv>
          )}
        </Grid>
        <Grid item lg={6} xl={8}>
          {selectedLayout ? (
            <Card>
              <CardContent>
                {selectedLayout.id === '' ? (
                  <TextField
                    id='newLayoutIdTextField'
                    label='Layout Name'
                    size='small'
                    value={newLayoutName}
                    onChange={e => setNewLayoutName(f => e.target.value)}
                  />
                ) : (
                  <h4>Layout Name: {selectedLayout.id}</h4>
                )}
                {selectedLayout ? (
                  <List>
                    {reportsData.cabinetOverviews
                      .filter(cabinet => {
                        let show = includeCabinetMap[cabinet.id] === false
                        return !show
                      })
                      .sort((a, b) => (a.cabinetName > b.cabinetName ? 1 : -1))
                      .map(cabinet => (
                        <ListItem key={cabinet.id} disablePadding>
                          <ListItemButton
                            sx={{ borderRadius: '20px', boxShadow: '0px 0px 25px -10px rgba(0,0,0,0.9)', marginBottom: '15px' }}
                          >
                            <ListItemIcon
                              id={`${cabinet.id}CabinetItemRemoveButton`}
                              onClick={() => {
                                setIncludeCabinetMap(map => ({ ...map, [cabinet.id]: false }))
                              }}
                            >
                              <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText primary={cabinet.cabinetName} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </List>
                ) : (
                  <CenteredDiv>Selected a layout to edit or create a new one.</CenteredDiv>
                )}
              </CardContent>
              <CardActions>
                <PrimaryButton
                  id='saveLayoutButton'
                  onClick={async () => {
                    await DtsReportsApi.setCardLayouts({
                      ...selectedLayout,
                      id: selectedLayout.id === '' ? newLayoutName : selectedLayout.id,
                      includedCabinets: Object.keys(includeCabinetMap).filter(id => includeCabinetMap[id] === true)
                    }).then(async () => await layoutsRefetch())
                    enqueueSnackbar('Saved layout configuration.', SnackbarVariants.SUCCESS)
                  }}
                >
                  Save
                </PrimaryButton>
              </CardActions>
            </Card>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  )
}
