import {
  CustomSelect,
  CustomDataGrid,
  WtxColors,
  SnackbarVariants,
  inDateRange,
  CustomCheckbox
} from '@wavetronix/common-components'
import {
  Typography,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Grid,
  Stack,
  Chip,
  IconButton,
  FormControlLabel,
  Switch,
  FormGroup
} from '@mui/material'
import { useState, useMemo, useEffect } from 'react'
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff'
import BarChartIcon from '@mui/icons-material/BarChart'
import MenuIcon from '@mui/icons-material/Menu'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { useSnackbar } from 'notistack'
import { PieChart, Sector, Pie, Cell, ResponsiveContainer } from 'recharts'
import minMax from 'dayjs/plugin/minMax'
import DtsReportsApi from '../api/DtsReportsApi'
import { env } from '../index.js'
import { v4 as uuidv4 } from 'uuid'
import { versionComparator } from '../utils/sortComparators'
import NumbersIcon from '@mui/icons-material/Numbers'
import PercentIcon from '@mui/icons-material/Percent'
import PassFailBarGraph from './graphs/PassFailBarGraph'
import PassedPercentLineGraph from './graphs/PassedPercentLineGraph'
import { CONNECTION_TYPES } from '../utils/connectionTypes.js'

dayjs.extend(minMax)

const filterTests = (filter, tests) =>
  tests.filter(test => {
    return (
      (filter.branch === 'All' || filter.branch === test.branch) &&
      (filter.connectionType === '' ||
        (filter.connectionType === 'LAN' && test.connectionType === 'LAN') ||
        (filter.connectionType === 'WAN' && test.connectionType === 'WAN') ||
        (filter.connectionType === 'DUAL' && test.connectionType === 'DUAL')) &&
      (filter.version === '' || (test.version && test.version.includes(filter.version))) &&
      inDateRange(test.startTime, filter.startDate, filter.endDate) &&
      (filter.result === 'Both' || (filter.result === 'Pass' ? test.passed === true : test.passed === false)) &&
      (filter.cabinetName === 'All' || test.cabinetName.toLowerCase().includes(filter.cabinetName.toLowerCase())) &&
      filter.isHappyPath === test.isHappyPath &&
      filter.isDC === test.isDC &&
      (filter.isDC === true ? filter.isLow === test.isLow : true)
    )
  })

export const DEFAULT_TEST_FILTER = {
  branch: 'Master',
  startDate: null,
  endDate: null,
  version: '',
  cabinetName: 'All',
  result: 'Both',
  originalStartDate: null,
  connectionType: '',
  isHappyPath: false,
  isDC: false,
  isLow: false
}

const columns = [
  {
    field: 'passed',
    headerName: 'Status',
    flex: 0.5,
    renderCell: data => {
      if (data.row.passed === true) {
        return <CheckCircleIcon sx={{ color: WtxColors.GROWTH_GREEN }} />
      } else {
        return <CancelIcon sx={{ color: WtxColors.INNOVATION_RED }} />
      }
    }
  },
  {
    field: 'branch',
    headerName: 'Branch',
    flex: 1
  },
  {
    field: 'version',
    headerName: 'Version',
    flex: 1
  },
  {
    field: 'cabinetName',
    headerName: 'Cabinet Name',
    flex: 1
  },
  {
    field: 'machineName',
    headerName: 'Machine Name',
    flex: 1
  },
  {
    field: 'startTime',
    headerName: 'Time',
    flex: 1,
    valueGetter: u => dayjs(u.row.startTime).format('MM/DD/YYYY hh:mm:ss')
  }
]

const BAR_TYPE_OPTIONS = ['cabinetName', 'version', 'machineName', 'time']
const TIME_OPTIONS = { hour: 13, day: 10, month: 7 }

export default function MetricsDisplay({ selectedTest, filter, setFilter }) {
  const { enqueueSnackbar } = useSnackbar()
  const [displayValue, setDisplayValue] = useState('circle')
  const [barOption, setBarOption] = useState('version')
  const [timeOption, setTimeOption] = useState('day')
  const [sortOption, setSortOption] = useState('percent')
  const [showPercentLabels, setShowPercentLabels] = useState(false)

  const cabinetOptions = useMemo(() => {
    if (selectedTest) {
      let res = new Set(selectedTest.results.map(t => t.cabinetName))
      return Array.from(['All', ...res])
    }
  }, [selectedTest])

  const filteredTests = useMemo(() => {
    if (selectedTest) {
      return filterTests(filter, selectedTest.results).sort((a, b) => (a.startTime < b.startTime ? 1 : -1))
    }
  }, [selectedTest, filter])

  const origDates = useMemo(() => {
    let datesObj = {
      startDate: null,
      endDate: null
    }
    if (selectedTest && selectedTest.results) {
      let dates = selectedTest.results.map(test => dayjs(test.startTime))

      datesObj = { startDate: dayjs.min(dates), endDate: dayjs.max(dates) }
    }

    return datesObj
  }, [selectedTest])

  useEffect(() => {
    if (selectedTest && origDates) {
      setFilter(f => ({
        ...f,
        startDate: f.startDate ? f.startDate : origDates.startDate,
        endDate: f.endData ? f.endDate : origDates.endDate,
        originalStartDate: origDates.startDate
      }))
    }
  }, [selectedTest, setFilter, origDates])

  const circleData = useMemo(() => {
    if (filteredTests) {
      let passedCount = filteredTests.filter(test => test.passed === true).length
      return [
        {
          name: 'Passed',
          value: passedCount
        },
        { name: 'Failed', value: filteredTests.length - passedCount }
      ]
    }
  }, [filteredTests])

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment) {
      setDisplayValue(newAlignment)
    }
  }

  const barData = useMemo(() => {
    let oneTimeMessage = false
    const calculateTime = (time, type) => {
      return time.slice(0, TIME_OPTIONS[type])
    }

    if (filteredTests) {
      let mapResult = filteredTests.reduce((map, obj) => {
        if (barOption === 'time') {
          let objTime = calculateTime(obj.startTime, timeOption)
          if (objTime in map) {
            if (obj.passed === true) {
              map[`${objTime}`].passed += 1
            } else {
              map[`${objTime}`].failed += 1
            }
          } else {
            map[`${objTime}`] = {
              option: objTime,
              passed: obj.passed === true ? 1 : 0,
              failed: obj.passed === false ? 1 : 0
            }
          }
        } else {
          if (!(barOption in obj)) {
            if (oneTimeMessage === false) {
              enqueueSnackbar(
                `A test report does not contain the variable ${barOption}. Graph will be incomplete or empty.`,
                SnackbarVariants.ERROR
              )
              oneTimeMessage = true
            }
            return map
          }

          if (obj[`${barOption}`] in map) {
            if (obj.passed === true) {
              map[`${obj[`${barOption}`]}`].passed += 1
            } else {
              map[`${obj[`${barOption}`]}`].failed += 1
            }
          } else {
            map[`${obj[`${barOption}`]}`] = {
              option: obj[`${barOption}`],
              passed: obj.passed === true ? 1 : 0,
              failed: obj.passed === false ? 1 : 0
            }
          }
        }
        return map
      }, {})

      for (let value of Object.entries(mapResult)) {
        let passed = value[1].passed
        let total = value[1].passed + value[1].failed
        mapResult[value[0]] = { ...value[1], passedPercent: ((passed / total) * 100).toFixed(2) }
      }

      return Object.values(mapResult).sort((a, b) =>
        barOption === 'version' ? versionComparator(a.option, b.option) : a.option > b.option ? 1 : -1
      )
    }
  }, [barOption, filteredTests, enqueueSnackbar, timeOption])

  const renderCustomizedLabel = (props: any) => {
    const RADIAN = Math.PI / 180
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 30) * cos
    const my = cy + (outerRadius + 30) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 22
    const ey = my
    const textAnchor = cos >= 0 ? 'start' : 'end'

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill='#333'>{`${payload.name} - ${value}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill='#999'>
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    )
  }

  return (
    <div>
      <Typography variant='h4'>{selectedTest.testName}</Typography>
      <Grid container sx={{ marginTop: '15px', width: '100%' }}>
        <Grid item lg={8} md={12}>
          <Box sx={{ display: 'flex' }}>
            <CustomSelect
              id='branchSelect'
              label='Branch'
              style={{ width: '150px' }}
              value={filter.branch}
              options={['Dev', 'Integration', 'Master']}
              onChange={e => setFilter(f => ({ ...f, branch: e.target.value }))}
            />
            <CustomSelect
              id='connectionTypeSelect'
              label='Connection'
              style={{ width: '150px' }}
              value={filter.connectionType}
              options={CONNECTION_TYPES}
              onChange={e => setFilter(f => ({ ...f, connectionType: e.target.value }))}
            />
            <FormGroup sx={{ marginLeft: '10px' }} row>
              <FormControlLabel
                control={
                  <Switch
                    id='isDCSwitch'
                    checked={filter.isHappyPath}
                    color='primary'
                    onChange={e => setFilter(f => ({ ...f, isHappyPath: e.target.checked }))}
                  />
                }
                label='Is Happy'
              />
              <FormControlLabel
                control={
                  <Switch
                    id='isDCSelect'
                    checked={filter.isDC}
                    color='primary'
                    onChange={e => setFilter(f => ({ ...f, isDC: e.target.checked }))}
                  />
                }
                label='Is DC'
              />
              {filter.isDC ? (
                <FormControlLabel
                  control={
                    <Switch
                      id='isLowSelect'
                      checked={filter.isLow}
                      color='primary'
                      onChange={e => setFilter(f => ({ ...f, isLow: e.target.checked }))}
                    />
                  }
                  label='Is Low'
                />
              ) : null}
            </FormGroup>
          </Box>
          <Box sx={{ display: 'flex', marginTop: '10px' }}>
            <TextField
              id='versionTextField'
              label='Version'
              size='small'
              value={filter.version}
              onChange={e => setFilter(f => ({ ...f, version: e.target.value }))}
            />
            <CustomSelect
              id='testResultSelect'
              label='Test Result'
              style={{ width: '150px' }}
              value={filter.result}
              options={['Both', 'Pass', 'Fail']}
              onChange={e => setFilter(f => ({ ...f, result: e.target.value }))}
            />
            <CustomSelect
              id='cabinetNameSelect'
              label='Cabinet Name'
              style={{ width: '250px' }}
              value={filter.cabinetName}
              options={cabinetOptions ? cabinetOptions : []}
              onChange={e => setFilter(f => ({ ...f, cabinetName: e.target.value }))}
            />
          </Box>
        </Grid>
        <Grid item lg={4} md={12}>
          <Stack sx={{ float: 'right' }}>
            <Box sx={{ float: 'right', display: 'flex', marginRight: '0px', marginLeft: 'auto' }}>
              <Chip
                id='pastWeekChip'
                label='Past Week'
                onClick={() => {
                  var newStartDate = dayjs(filter.endDate).add(-7, 'day')

                  setFilter(f => ({ ...f, startDate: newStartDate }))
                }}
              />
              <Chip
                id='pastMonthChip'
                label='Past Month'
                onClick={() => {
                  var newStartDate = dayjs(filter.endDate).add(-1, 'month')

                  setFilter(f => ({ ...f, startDate: newStartDate }))
                }}
              />
              <Chip
                id='resetDateChip'
                label='Reset'
                onClick={() => {
                  setFilter({
                    ...DEFAULT_TEST_FILTER,
                    originalStartDate: origDates.startDate,
                    startDate: origDates.startDate,
                    endDate: origDates.endDate
                  })
                }}
              />
            </Box>
            <Box sx={{ float: 'right', marginTop: '15px', display: 'flex' }}>
              <DatePicker
                id='startDatePicker'
                label='From'
                inputFormat='MM/DD/YYYY'
                value={filter.startDate}
                onChange={date => setFilter(f => ({ ...f, startDate: date }))}
                textField={params => <TextField size='small' {...params} />}
              />
              <DatePicker
                id='endDatePicker'
                label='To'
                inputFormat='MM/DD/YYYY'
                value={filter.endDate}
                onChange={date => setFilter(f => ({ ...f, endDate: date }))}
                textField={params => <TextField size='small' sx={{ marginLeft: '5px' }} {...params} />}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '15px' }}>
        <ToggleButtonGroup value={displayValue} exclusive onChange={handleAlignment}>
          <ToggleButton id='circleToggleButton' value='circle'>
            <DataSaverOffIcon />
          </ToggleButton>
          <ToggleButton id='graphToggleButton' value='graph'>
            <BarChartIcon />
          </ToggleButton>
          <ToggleButton id='listToggleButton' value='list'>
            <MenuIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box
        sx={{
          width: '100%',
          marginTop: '15px',
          bgcolor: 'background.default',
          borderRadius: '30px'
        }}
      >
        {displayValue === 'list' ? (
          <div style={{ margin: '0px 15px', paddingTop: '15px' }}>
            <CustomDataGrid
              getRowId={row => {
                return uuidv4()
              }}
              rows={filteredTests}
              columns={columns}
              onRowClick={async row => {
                const exists = await DtsReportsApi.logFileExists(row.row.reportId)

                if (exists === true) {
                  window.open(`${env.urls.dtsURL}/logs/${row.row.reportId}`, '_blank')
                } else {
                  enqueueSnackbar(`Log for this test does not exist`, {
                    variant: 'warning',
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center'
                    }
                  })
                }
              }}
            />
          </div>
        ) : displayValue === 'graph' ? (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <CustomSelect
                id='graphBarOptionSelect'
                label='Graph Option'
                style={{ width: '150px', marginTop: '15px', marginLeft: '15px' }}
                value={barOption}
                options={BAR_TYPE_OPTIONS}
                onChange={e => setBarOption(e.target.value)}
              />
              {barOption === 'time' ? (
                <CustomSelect
                  id='graphTimeOptionSelect'
                  label='Time Option'
                  style={{ width: '150px', marginTop: '15px' }}
                  value={timeOption}
                  options={Object.keys(TIME_OPTIONS)}
                  onChange={e => setTimeOption(e.target.value)}
                />
              ) : (
                <></>
              )}
              <Box sx={{ flex: '1 1 auto' }} />
              <Stack>
                <Box>
                  <IconButton id='sortCountButton' onClick={() => setSortOption('count')} sx={{ margin: '9px 0px' }}>
                    <NumbersIcon sx={{ color: sortOption === 'count' ? WtxColors.IQ_BLUE : 'default' }} fontSize='small' />
                  </IconButton>
                  <IconButton
                    id='percentCountButton'
                    onClick={() => setSortOption('percent')}
                    sx={{ margin: '9px 0px', marginRight: '15px' }}
                  >
                    <PercentIcon sx={{ color: sortOption === 'percent' ? WtxColors.IQ_BLUE : 'default' }} fontSize='small' />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
            <div style={{ padding: '15px' }}>
              {sortOption === 'count' ? (
                <PassFailBarGraph barData={barData} />
              ) : (
                <>
                  <PassedPercentLineGraph barData={barData} showPercentLabels={showPercentLabels} />
                  <Box>
                    <CustomCheckbox
                      id='showPercentPointsCheckbox'
                      checked={showPercentLabels === true}
                      onChange={e => setShowPercentLabels(value => value === false)}
                    />
                    Show Percent Points
                  </Box>
                </>
              )}
            </div>
          </Box>
        ) : displayValue === 'circle' ? (
          <>
            <ResponsiveContainer height={275} width={'100%'}>
              <PieChart width={800} height={200}>
                <Pie
                  label={renderCustomizedLabel}
                  data={circleData}
                  innerRadius={60}
                  outerRadius={80}
                  fill='#8884d8'
                  paddingAngle={2}
                  dataKey='value'
                >
                  {circleData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.name === 'Passed' ? WtxColors.GROWTH_GREEN : WtxColors.INNOVATION_RED}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <Stack sx={{ width: '100%', alignItems: 'center', paddingBottom: '15px' }} spacing={2}>
              <h4>Total Pass/Fail Rate</h4>
              <div>Total Amount: {filteredTests.length}</div>
            </Stack>
          </>
        ) : (
          <div>Error: Invalid display value option.</div>
        )}
      </Box>
    </div>
  )
}
