import ButtonBase from '@mui/material/ButtonBase'
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { NorthOutlined, SouthOutlined } from '@mui/icons-material'
import { React } from 'react'
import { formatDate, formatTime } from '../utils/dateUtils.js'
import { calculateColorBasedOnPercent } from '../utils/colorOptions.js'
import { WtxColors } from '@wavetronix/common-components'

const classes = {
  buttonBase: {
    display: 'block',
    width: '100%',
    height: '88%',
    color: 'black',
    background: 'white',
    borderRadius: '15px'
  },
  imageBase: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '60px',
    marginBottom: 'auto'
  },
  titleText: {
    fontFamily: 'Proxima Nova',
    fontWeight: 'bold',
    fontSize: 20,
    display: 'inline',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    color: 'black'
  },
  titleIcon: {
    display: 'block',
    maxHeight: '180px'
  }
}

export default function SensorCardEx({ statusItem, disableNavigation }) {
  const getUrl = () => {
    return `/product?c=${statusItem.cabinetName}`
  }

  const getSuccessChange = dtsReport => {
    if (dtsReport.isSuccessChangeValid && dtsReport.successChange < 0)
      return (
        <div>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
            <SouthOutlined style={{ fill: WtxColors.INNOVATION_RED }}></SouthOutlined>
            <div>{`${(dtsReport.successChange * 100).toFixed(1)}%`}</div>
          </div>
        </div>
      )
    if (dtsReport.isSuccessChangeValid && dtsReport.successChange > 0)
      return (
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
          <NorthOutlined style={{ fill: WtxColors.GROWTH_GREEN }}></NorthOutlined>
          <div>{`${(dtsReport.successChange * 100).toFixed(1)}%`}</div>
        </div>
      )

    return <div>{'--'}</div>
  }

  const getBranchData = branchName => {
    let branchDataList = statusItem.testOverviews.filter(item => item.branch === branchName)
    if (branchDataList.length > 0) {
      let branchData = branchDataList[0]
      let branchSuccessPercent = (branchData.percentSuccess * 100).toFixed(1)
      let branchResult = calculateColorBasedOnPercent(branchSuccessPercent)
      return (
        <TableRow sx={{ backgroundColor: `${branchResult.backgroundColor}80` }}>
          <TableCell sx={{ fontWeight: 'bold', maxWidth: '35px' }}>{branchData.branch}</TableCell>
          <TableCell align='right'>
            <span style={{ fontFamily: 'Source Code Pro', fontSize: '12px' }}>{`${formatDate(branchData.lastRun)}`}</span>
            <span style={{ marginLeft: '5px', fontFamily: 'Source Code Pro', fontSize: '12px' }}>{`${formatTime(
              branchData.lastRun
            )}`}</span>
          </TableCell>
          <TableCell align='right'>{branchData.successCount}</TableCell>
          <TableCell align='right'>{branchData.failureCount}</TableCell>
          <TableCell align='right'>{`${branchSuccessPercent}%`}</TableCell>
          <TableCell align='center'>{getSuccessChange(branchData)}</TableCell>
        </TableRow>
      )
    }
    return (
      <TableRow>
        <TableCell>{branchName}</TableCell>
        <TableCell align='right'>{'--'}</TableCell>
        <TableCell align='right'>{'--'}</TableCell>
        <TableCell align='right'>{'--'}</TableCell>
        <TableCell align='right'>{'--'}</TableCell>
        <TableCell align='center'>{'--'}</TableCell>
      </TableRow>
    )
  }

  return (
    <Grid item xs={4} sx={{ maxHeight: 200 }}>
      <div
        id={`${statusItem.cabinetName}ViewCard`}
        onClick={_ => {
          if (disableNavigation === false) {
            return (window.location.href = getUrl())
          }
        }}
      >
        <ButtonBase sx={classes.buttonBase}>
          <div style={{ minHeight: '200px', marginTop: '2px' }}>
            <div style={{ textAlign: 'center', marginBottom: '5px' }}>
              <div style={classes.titleText}>{statusItem.cabinetName}</div>
              <div>
                {statusItem.testOverviews && statusItem.testOverviews.length > 0 && statusItem.testOverviews[0].testDevices
                  ? statusItem.testOverviews[0].testDevices.map(d => d.product).join(' | ')
                  : ''}
              </div>
            </div>
            <div>
              <TableContainer>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold', maxWidth: '35px' }}>Branch</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                        Last Run
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                        Pass
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                        Fail
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                        % Pass
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }} align='center'>
                        Change
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getBranchData('Master')}
                    {getBranchData('Integration')}
                    {getBranchData('Dev')}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </ButtonBase>
      </div>
    </Grid>
  )
}
