import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  InputAdornment,
  Toolbar,
  Button
} from '@mui/material'
import { useMemo, useState, useEffect } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import ProductMetricsDisplay, { DEFAULT_TEST_FILTER } from './ProductMetricsDisplay'
import LoadingPageMessage from './LoadingPageMessage'
import { CustomCheckbox } from '@wavetronix/common-components'
import { ColorOptions } from '../utils/colorOptions.js'

const COLOR_LIST = Object.values(ColorOptions)
const drawerWidth = 300

export default function ProductMetrics({ isLoginRequired, allTests, allTestsLoading }) {
  const [filter, setFilter] = useState(DEFAULT_TEST_FILTER)

  const [calculatingProductMap, setCalculatingProductMap] = useState(true)
  const [searchProduct, setSearchProduct] = useState('')
  const [searchTest, setSearchTest] = useState('')
  const [testMap, setTestMap] = useState({})

  const [selectedProduct, setSelectedProduct] = useState(null)

  useEffect(() => {
    if (allTests) {
      let tMap = allTests.reduce((map, obj) => {
        for (let test of obj.results) {
          map[test.testName] = true
        }
        return map
      }, {})

      setTestMap({ ...tMap })
    }
  }, [allTests])

  const productMap = useMemo(() => {
    let tMap = {}
    if (allTests) {
      tMap = allTests.reduce((map, obj) => {
        for (let device of obj.testDevices) {
          if (device.product in map) {
            map[device.product].results.push({ ...obj, softwareVersion: device.softwareVersion })
          } else {
            map[device.product] = {
              productName: device.product,
              results: [{ ...obj, softwareVersion: device.softwareVersion }]
            }
          }
        }
        return map
      }, {})
    }
    setCalculatingProductMap(false)
    return tMap
  }, [allTests])

  document.getElementById('appTitle').innerHTML = `Product Metrics`

  if (allTestsLoading) {
    return <LoadingPageMessage message={'Querying tests from database. Please wait.'} />
  }

  if (calculatingProductMap === true) {
    return <LoadingPageMessage message={'Anaylzing query results...'} />
  }

  return (
    <>
      <Toolbar />
      <Box sx={{ display: 'flex' }}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box'
            }
          }}
          variant='permanent'
          anchor='left'
        >
          <Toolbar />
          <TextField
            id='searchProductTextField'
            value={searchProduct}
            onChange={e => setSearchProduct(e.target.value)}
            size='small'
            sx={{ margin: '10px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <List sx={{ paddingTop: '0px' }}>
            {Object.keys(productMap)
              .sort((a, b) => {
                return a.toLowerCase() > b.toLowerCase() ? 1 : -1
              })
              .filter(testName => testName.toLowerCase().includes(searchTest.toLowerCase()))
              .map((testName, index) => (
                <ListItem id={`${testName}`} key={testName} disablePadding>
                  <ListItemButton
                    id={`${testName}Button`}
                    onClick={() => setSelectedProduct({ testName: testName, ...productMap[testName] })}
                  >
                    <ListItemText id={`${testName}Text`} primary={testName} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Drawer>

        <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
          {selectedProduct ? (
            <ProductMetricsDisplay selectedProduct={selectedProduct} filter={filter} setFilter={setFilter} testMap={testMap} />
          ) : (
            <Box
              sx={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginTop: '150px'
              }}
            >
              <h2>Select a product to view metrics.</h2>
            </Box>
          )}
        </Box>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box'
            }
          }}
          variant='permanent'
          anchor='right'
        >
          <Toolbar />
          <TextField
            id='searchTestTextField'
            value={searchTest}
            onChange={e => setSearchTest(e.target.value)}
            size='small'
            sx={{ margin: '10px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              id='allTestsButton'
              onClick={() => {
                setTestMap(tMap => ({
                  ...Object.keys(tMap).reduce((map, obj) => {
                    map[obj] = true
                    return map
                  }, [])
                }))
              }}
            >
              ALL
            </Button>
            <Button
              id='noneTestsButton'
              onClick={() => {
                setTestMap(tMap => ({
                  ...Object.keys(tMap).reduce((map, obj) => {
                    map[obj] = false
                    return map
                  }, [])
                }))
              }}
            >
              NONE
            </Button>
          </Box>
          <List sx={{ paddingTop: '0px' }}>
            {Object.keys(testMap)
              .sort((a, b) => {
                return a.toLowerCase() > b.toLowerCase() ? 1 : -1
              })
              .filter(testName => testName.toLowerCase().includes(searchTest.toLowerCase()))
              .map((testName, index) => (
                <ListItem id={testName} key={testName} disablePadding>
                  <CustomCheckbox
                    id={`${testName}Checkbox`}
                    onChange={e => {
                      setTestMap(tmap => ({ ...tmap, [`${testName}`]: e.target.checked === true }))
                    }}
                    checked={testMap[testName]}
                  />
                  <ListItemText
                    id={`${testName}Text`}
                    primary={testName}
                    sx={{ color: COLOR_LIST[Object.keys(testMap).indexOf(testName)] }}
                  />
                </ListItem>
              ))}
          </List>
        </Drawer>
      </Box>
    </>
  )
}
