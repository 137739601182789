import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { CenteredDiv } from '@wavetronix/common-components'
import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import DtsReportsApi from '../api/DtsReportsApi'
import { Toolbar, Grid } from '@mui/material'
import ReportDisplay from './ReportDisplay'
import dayjs from 'dayjs'
import FailedTestModal from './FailedTestModal'
import version from '../env/version.json'
import LoadingPageMessage from './LoadingPageMessage'
import { multiVersionComparator } from '../utils/sortComparators'
import { useLocation, useNavigate } from 'react-router-dom'
import { CABINET_LINEOPTIONS } from '../components/graphs/FullCabinetLineGraph'
const checkForChangesIntervalInSeconds = 15 * 60

function getOverview(data, branch, cabinetName) {
  let cabinetOverview = data.cabinetOverviews.filter(o => o.cabinetName === cabinetName)
  if (cabinetOverview && cabinetOverview.length > 0) {
    let overview = cabinetOverview[0].testOverviews.filter(o => o.branch === branch)
    if (overview && overview.length > 0) {
      return overview[0]
    }
    return null
  }
  return null
}

function Dashboard({
  isLoginRequired,
  currentCabinet,
  setCurrentCabinet,
  reportsData,
  reportsDataLoading,
  reportsRefetch,
  errorModalVisible,
  setErrorModalVisible,
  setErrorExists,
  reports,
  setReports,
  daysFrom
}) {
  const { instance, accounts } = useMsal()
  const navigate = useNavigate()
  const [refreshDate, setRefreshDate] = useState('')
  const [refreshTime, setRefreshTime] = useState('')
  const location = useLocation()

  const { data: changeId } = useQuery({
    queryKey: ['changeIdData'],
    queryFn: () => DtsReportsApi.hasChanges(isLoginRequired, instance, accounts).then(hash => hash),
    refetchInterval: checkForChangesIntervalInSeconds * 1000
  })

  useEffect(() => {
    if (changeId) {
      reportsRefetch()
    }
  }, [changeId, reportsRefetch])

  const generateReportType = useCallback(report => {
    let fullTestType = `${report.connectionType}_HAPPY${report.isHappyPath}`

    if (report.isDC) {
      fullTestType += `_DC${report.isDC}_ISLOW${report.isLow}`
    }

    return fullTestType
  }, [])

  const currentPreviewReportReducter = useCallback(
    reports => {
      const reportsResult = Object.fromEntries(
        Object.keys(CABINET_LINEOPTIONS).map(key => [
          key,
          {
            report: null,
            previousReport: null,
            allReports: []
          }
        ])
      )

      for (let reportType of Object.keys(reportsResult)) {
        let reportTypeOptions = reports.filter(report => generateReportType(report) === reportType)
        reportsResult[reportType].allReports = reportTypeOptions
        reportsResult[reportType].report = reportTypeOptions.length > 0 ? reportTypeOptions[0] : null
        reportsResult[reportType].previousReport = reportTypeOptions.length > 1 ? reportTypeOptions[1] : null
      }

      return reportsResult
    },
    [generateReportType]
  )

  const reportsBarReducter = useCallback(
    reports => {
      let mapResult = reports.reduce((map, obj) => {
        const defaultTypes = Object.fromEntries(
          Object.keys(CABINET_LINEOPTIONS).map(key => [
            key,
            {
              passed: 0,
              total: 0,
              passedPercent: null
            }
          ])
        )
        let testVersion = obj.testDevices.map(device => `${device.product}PRODUCT${device.softwareVersion}`).join('VERSION')
        if (!(`${testVersion}` in map)) {
          map[`${testVersion}`] = {
            option: `${testVersion}`,
            totalCount: 0,
            testDevices: [...obj.testDevices],
            ...defaultTypes
          }
        }

        let fullTestType = generateReportType(obj)

        if (!(`${fullTestType}` in map[`${testVersion}`])) {
          map[`${testVersion}`][`${fullTestType}`] = {
            passed: 0,
            total: 0,
            passedPercent: 0
          }
        }

        for (let singleTest of obj.results) {
          if (singleTest.passed === true) {
            map[`${testVersion}`][`${fullTestType}`].passed += 1
          }
          map[`${testVersion}`][`${fullTestType}`].total += 1
        }

        map[`${testVersion}`][`${fullTestType}`].passedPercent = (
          (map[`${testVersion}`][`${fullTestType}`].passed / map[`${testVersion}`][`${fullTestType}`].total) *
          100
        ).toFixed(2)

        return map
      }, {})

      let reportsBarInfo = Object.values(mapResult)
        .reverse()
        .sort((a, b) => {
          return multiVersionComparator(a.option, b.option)
        })

      return reportsBarInfo
    },
    [generateReportType]
  )

  const DevData = useMemo(() => {
    if (reportsData) {
      let result = {
        overview: {},
        title: '',
        barData: [],
        reportInfo: {}
      }

      result.title = 'Dev'
      result.overview = getOverview(reportsData, 'Dev', currentCabinet)

      const lanReports = reportsData.lanReports.filter(report => report.branch === 'Dev' && report.cabinetName === currentCabinet)
      const wanReports = reportsData.wanReports.filter(report => report.branch === 'Dev' && report.cabinetName === currentCabinet)
      const dualReports = reportsData.dualReports.filter(
        report => report.branch === 'Dev' && report.cabinetName === currentCabinet
      )

      result.barData = reportsBarReducter([...lanReports, ...wanReports, ...dualReports])
      result.reportInfo = currentPreviewReportReducter([...lanReports, ...wanReports, ...dualReports])

      return result
    }
  }, [reportsData, currentCabinet, reportsBarReducter, currentPreviewReportReducter])

  const IntData = useMemo(() => {
    if (reportsData) {
      let result = {
        overview: {},
        title: '',
        barData: [],
        reportInfo: {}
      }

      result.title = 'Integration'
      result.overview = getOverview(reportsData, 'Integration', currentCabinet)

      const lanReports = reportsData.lanReports.filter(
        report => report.branch === 'Integration' && report.cabinetName === currentCabinet
      )
      const wanReports = reportsData.wanReports.filter(
        report => report.branch === 'Integration' && report.cabinetName === currentCabinet
      )
      const dualReports = reportsData.dualReports.filter(
        report => report.branch === 'Integration' && report.cabinetName === currentCabinet
      )

      result.barData = reportsBarReducter([...lanReports, ...wanReports, ...dualReports])
      result.reportInfo = currentPreviewReportReducter([...lanReports, ...wanReports, ...dualReports])

      return result
    }
  }, [reportsData, currentCabinet, reportsBarReducter, currentPreviewReportReducter])

  const MasterData = useMemo(() => {
    if (reportsData) {
      let result = {
        overview: {},
        title: '',
        barData: [],
        reportInfo: {}
      }

      result.title = 'Master'
      result.overview = getOverview(reportsData, 'Master', currentCabinet)

      const lanReports = reportsData.lanReports.filter(
        report => report.branch === 'Master' && report.cabinetName === currentCabinet
      )
      const wanReports = reportsData.wanReports.filter(
        report => report.branch === 'Master' && report.cabinetName === currentCabinet
      )
      const dualReports = reportsData.dualReports.filter(
        report => report.branch === 'Master' && report.cabinetName === currentCabinet
      )

      result.barData = reportsBarReducter([...lanReports, ...wanReports, ...dualReports])
      result.reportInfo = currentPreviewReportReducter([...lanReports, ...wanReports, ...dualReports])

      return result
    }
  }, [reportsData, currentCabinet, reportsBarReducter, currentPreviewReportReducter])

  useEffect(() => {
    if (reportsData && currentCabinet === '') {
      setCurrentCabinet(reportsData.cabinetOverviews[0].cabinetName)
    }
  }, [reportsData, currentCabinet, setCurrentCabinet])

  useEffect(() => {
    if (reports) {
      let currentTime = new Date()
      setRefreshDate(dayjs(currentTime).format('YYYY-MM-DD'))
      setRefreshTime(dayjs(currentTime).format('HH:mm:ss'))
    }
  }, [reports, changeId, setRefreshDate, setRefreshTime])

  useEffect(() => {
    async function fetchData() {
      // You can await here

      if (currentCabinet && currentCabinet !== '' && daysFrom && daysFrom !== '') {
        let reports = await DtsReportsApi.getCurrent(isLoginRequired, instance, accounts, daysFrom).then(hash => hash)
        setReports(reports)
        setErrorExists(false)
      }
    }
    if (!currentCabinet || currentCabinet !== '') fetchData()
    document.getElementById('appTitle').innerHTML = `${currentCabinet}`
  }, [currentCabinet, setReports, isLoginRequired, instance, accounts, changeId, setErrorExists, daysFrom])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has('c')) {
      let c = queryParams.get('c')
      if (currentCabinet === '' && c !== currentCabinet) {
        setCurrentCabinet(c)
      } else {
        queryParams.delete('c')
        navigate(
          {
            search: queryParams.toString()
          },
          { replace: true }
        )
      }
    }
  }, [currentCabinet, setCurrentCabinet, location.search, navigate])

  if (reportsDataLoading) {
    return <LoadingPageMessage message={'Loading product reports data... Please wait.'} />
  }

  return (
    <div>
      <div style={{ marginTop: '30px' }}>
        <Toolbar />
        <CenteredDiv>
          <span style={{ fontFamily: 'Proxima Nova', fontWeight: 'bold', fontSize: '18px' }}>Last Updated on </span>
          <span style={{ fontFamily: 'Source Code Pro', fontSize: '18px', marginLeft: '15px' }}>{refreshDate}</span>
          <span style={{ fontFamily: 'Source Code Pro', fontSize: '18px', marginLeft: '15px' }}>{refreshTime}</span>
        </CenteredDiv>
        <span
          style={{
            float: 'right',
            margin: '0px 20px 0px 0px',
            transform: 'translate(0%, -100%)'
          }}
        >{`v ${version.version}`}</span>
      </div>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <ReportDisplay reportInfo={DevData} />
        </Grid>
        <Grid item md={4}>
          <ReportDisplay reportInfo={IntData} />
        </Grid>
        <Grid item md={4}>
          <ReportDisplay reportInfo={MasterData} />
        </Grid>
      </Grid>
      <FailedTestModal
        visible={errorModalVisible}
        data={reportsData}
        onClose={() => setErrorModalVisible(false)}
      ></FailedTestModal>
    </div>
  )
}

export default Dashboard
