import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, LineChart, Line, LabelList } from 'recharts'
import { WtxColors } from '@wavetronix/common-components'
import { ColorOptions } from '../../utils/colorOptions.js'
import { useMemo, useState } from 'react'

const COLOR_LIST = Object.values(ColorOptions)

export default function PassedPercentLineGraph({ barData, customXAxis = false, showPercentLabels = false }) {
  const [tooltip, setToolTip] = useState()

  const CustomTooltip = ({ active, payload, tooltip, barData }) => {
    const productInfo = useMemo(() => {
      if (payload && barData && payload.length > 0 && payload[0].payload) {
        let products = barData.filter(bar => bar.option === payload[0].payload.option)
        return products ? products[0] : null
      }
    }, [payload, barData])

    if (!active || !tooltip || (!payload && !payload[0].payload) || !barData || !productInfo) return null

    return (
      <div
        style={{
          backgroundColor: 'white',
          padding: '20px',
          opacity: 1,
          border: '2px solid black',
          borderRadius: '15px',
          color: 'black'
        }}>
        {productInfo.passedPercent}%
        {productInfo.testDevices ? (
          productInfo.testDevices.map((d, idx) => (
            <div key={idx} className='details'>
              <span style={{ marginRight: '10px', fontWeight: 'bold', display: 'inline-block', color: COLOR_LIST[d.port] }}>
                {d.product}
              </span>
              <span style={{ fontFamily: 'Source Code Pro' }}>{`Slot:${d.port} ${d.softwareVersion}`}</span>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
    )
  }

  const CustomTick = ({ x, y, stroke, payload, barData }) => {
    if (!payload || !barData) return null

    return (
      <g transform={`translate(${x},${y})`}>
        {barData[payload.value].testDevices.slice(0, 3).map((test, index) => (
          <text
            x={0}
            y={index * 15}
            dy={16}
            dx={30}
            textAnchor='end'
            fill='#666'
            key={index}
            className='productVersion'
            style={{
              fill: COLOR_LIST[test.port]
            }}>
            {test.softwareVersion.length > 13 ? `${test.softwareVersion.slice(0, 8)}...` : test.softwareVersion}
          </text>
        ))}
      </g>
    )
  }

  return (
    <ResponsiveContainer width='97%' aspect={4}>
      <LineChart
        width={450}
        height={700}
        data={barData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}>
        <CartesianGrid strokeDasharray='3 3' />
        {customXAxis === false ? <XAxis dataKey='option' /> : <XAxis tick={<CustomTick barData={barData} />} height={50} />}
        <YAxis domain={[0, 100]} />
        {customXAxis === false ? <Tooltip /> : <Tooltip content={<CustomTooltip tooltip={tooltip} barData={barData} />} />}
        <Line
          type='monotone'
          dataKey='passedPercent'
          stroke={WtxColors.GROWTH_GREEN}
          activeDot={{ r: 8 }}
          onMouseOver={() => setToolTip('option')}>
          {showPercentLabels === true ? <LabelList dataKey='passedPercent' position='top' /> : <></>}
        </Line>
      </LineChart>
    </ResponsiveContainer>
  )
}
