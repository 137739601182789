import { IconButton, AppBar, Box, Toolbar, Button, Select, MenuItem, Tooltip } from '@mui/material'
import { WarningAmber } from '@mui/icons-material'
import { useNavigate, useLocation } from 'react-router-dom'
import FilterAltIcon from '@mui/icons-material/FilterAlt'

const classes = {
  title: {
    fontFamily: 'Klavika',
    fontSize: 40,
    color: 'White'
  },
  comboText: {
    fontFamily: 'Proxima Nova',
    fontSize: 30
  }
}

export default function DTSAppBar({
  currentCabinet,
  handleCabinetChange,
  sortedCabinetNames,
  setErrorModalVisible,
  errorExists,
  setReportDataDateFilter
}) {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <AppBar component='nav' position='fixed' sx={{ zIndex: theme => theme.zIndex.drawer + 1, backgroundColor: 'black' }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
          {sortedCabinetNames && location.pathname === '/product' ? (
            <Select
              id='cabinetSelect'
              value={currentCabinet}
              onChange={handleCabinetChange}
              sx={{
                fontFamily: 'Klavika',
                fontSize: 30,
                color: 'White',
                border: '0px solid black',
                '& .MuiSvgIcon-root': {
                  color: 'white'
                },
                '&.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black'
                  },
                  '&:hover fieldset': {
                    borderColor: 'black'
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black'
                  }
                }
              }}
            >
              {sortedCabinetNames.map((cabinetName, idx) => {
                return (
                  <MenuItem id={`${cabinetName}MenuItem`} key={idx} value={cabinetName} sx={classes.comboText}>
                    {cabinetName}
                  </MenuItem>
                )
              })}
            </Select>
          ) : (
            <></>
          )}
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Button id='OverviewPageButton' sx={{ color: '#fff' }} onClick={() => navigate('/overview')}>
            {'Overview'}
          </Button>
          <Button id='ProductMetricsPageButton' sx={{ color: '#fff' }} onClick={() => navigate('/productmetrics')}>
            {'Product Metrics'}
          </Button>
          <Button id='TestMetricsPageButton' sx={{ color: '#fff' }} onClick={() => navigate('/testmetrics')}>
            {'Test Metrics'}
          </Button>
          <Tooltip title={'Edit Date Filter'}>
            <IconButton
              id='filterReportDataModalButton'
              onClick={() => {
                setReportDataDateFilter(f => ({ ...f, open: true }))
              }}
            >
              <FilterAltIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
          {errorExists ? (
            <IconButton id='errorModalButton' onClick={() => setErrorModalVisible(true)} style={{ marginLeft: '300px' }}>
              <WarningAmber style={{ color: 'yellow', scale: '2' }}></WarningAmber>
            </IconButton>
          ) : (
            <div></div>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  )
}
