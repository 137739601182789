import { CenteredDiv, ErrorMessage, SetupProvider } from '@wavetronix/common-components'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import DtsReportsApi from '../api/DtsReportsApi'
import { env } from '../index.js'
import MainPage from './MainPage'
import NoLogin from './NoLogin'
import LoadingPageMessage from './LoadingPageMessage'

function MaybeLogin({ msalInstance }) {
  const { data, error, isLoading } = useQuery({
    queryKey: ['loginRequired'],
    queryFn: async () => await DtsReportsApi.isLoginRequired()
  })

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  if (isLoading || data === undefined) {
    return <LoadingPageMessage message={'Attempting to login... Please wait.'} />
  } else if (data) {
    return (
      <SetupProvider env={env} msalInstance={msalInstance} allowedRoles={['Wavetronix Employee']}>
        <MainPage />
      </SetupProvider>
    )
  } else {
    return <NoLogin />
  }
}

export default MaybeLogin
