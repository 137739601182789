import { Legend, ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from 'recharts'
import { WtxColors } from '@wavetronix/common-components'

export default function PassFailBarGraph({ barData }) {
  return (
    <ResponsiveContainer width='97%' height={600}>
      <BarChart
        width={500}
        height={200}
        data={barData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5
        }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='option' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey='passed' stackId='a' fill={WtxColors.GROWTH_GREEN} />
        <Bar dataKey='failed' stackId='a' fill={WtxColors.INNOVATION_RED} />
      </BarChart>
    </ResponsiveContainer>
  )
}
