import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { CenteredDiv, RegularButton } from '@wavetronix/common-components'
import FailedTestDetails from './FailedTestDetails'

export default function FailedTestModal({ visible, data, onClose }) {
  const [failedReports, setFailedReports] = useState([])

  function close() {
    onClose()
  }

  useEffect(() => {
    if (data) {
      let failed = data.reports.filter(report => report.cabinetName === '')
      setFailedReports(failed)
    }
  }, [data, setFailedReports])

  return (
    <Dialog id='FailedTestModal' onClose={onClose} open={visible}>
      <DialogContent>
        <CenteredDiv>
          <span style={{ fontFamily: 'Proxima Nova', fontSize: '20px' }}>
            {'These test runs failed due to no devices being discovered'}
          </span>
        </CenteredDiv>
        <div style={{ minWidth: '500px' }}>
          {failedReports.map((report, idx) => {
            return <FailedTestDetails key={idx} report={report}></FailedTestDetails>
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <RegularButton id='FailedTestModalCloseButton' autoFocus onClick={close}>
          &nbsp;{'Done'}
        </RegularButton>
      </DialogActions>
    </Dialog>
  )
}
