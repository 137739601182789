import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'
import { Buffer } from 'buffer'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

let instance = null

function getConfiguration(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

class DtsReportsApi {
  isLoginRequired = async () => {
    let loginRequired = false
    await axios
      .get(`${env.urls.dtsURL}/reports/internal/check`, {
        timeout: 30000
      })
      .catch(err => {
        if (err.response) {
          loginRequired = true
        }
      })
    return loginRequired
  }

  getAll = async (isLoginRequired, msalInstance, accounts, daysFrom) => {
    if (!isLoginRequired) {
      return await axios.get(`${env.urls.dtsURL}/reports/internal/all?daysFrom=${daysFrom}`).then(res => res.data)
    }
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .get(`${env.urls.dtsURL}/reports/external/all?daysFrom=${daysFrom}`, getConfiguration(token))
      .then(res => res.data)
  }

  getCurrent = async (isLoginRequired, msalInstance, accounts, daysFrom) => {
    if (!isLoginRequired) {
      return await axios.get(`${env.urls.dtsURL}/reports/internal/current?daysFrom=${daysFrom}`).then(res => res.data)
    }
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .get(`${env.urls.dtsURL}/reports/current?daysFrom=${daysFrom}`, getConfiguration(token))
      .then(res => res.data)
  }

  getOverview = async (isLoginRequired, msalInstance, accounts) => {
    if (!isLoginRequired) {
      return await axios.get(`${env.urls.dtsURL}/reports/internal/current/overview`).then(res => res.data)
    }
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios.get(`${env.urls.dtsURL}/reports/current/overview`, getConfiguration(token)).then(res => res.data)
  }

  getSensors = async (isLoginRequired, msalInstance, accounts) => {
    if (!isLoginRequired) {
      return await axios.get(`${env.urls.dtsURL}/reports/internal/sensors`).then(res => res.data)
    }
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios.get(`${env.urls.dtsURL}/reports/sensors`, getConfiguration(token)).then(res => res.data)
  }

  getArcs = async (isLoginRequired, msalInstance, accounts) => {
    if (!isLoginRequired) {
      return await axios.get(`${env.urls.dtsURL}/reports/internal/arcs`).then(res => res.data)
    }
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios.get(`${env.urls.dtsURL}/reports/arcs`, getConfiguration(token)).then(res => res.data)
  }

  hasChanges = async (isLoginRequired, msalInstance, accounts) => {
    if (!isLoginRequired) {
      return await axios.get(`${env.urls.dtsURL}/reports/internal/changes`).then(res => res.data)
    }
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios.get(`${env.urls.dtsURL}/reports/changes`, getConfiguration(token)).then(res => res.data)
  }

  logFileExists = async reportId => {
    let exists = true
    await axios.get(`${env.urls.dtsURL}/logs/exists/${reportId}`).catch(error => {
      exists = false
    })
    return exists
  }

  getLogFile = async reportId => {
    return await axios.get(`${env.urls.dtsURL}/logs/${reportId}`).then(res => res.data)
  }

  getCardLayout = async (id = 'InternalLayout') => {
    let credentials = Buffer.from(`${env.basicAuthentication.dtsUsername}:${env.basicAuthentication.dtsPassword}`).toString(
      'base64'
    )

    return await axios
      .get(`${env.urls.dtsURL}/configuration/${id}`, {
        headers: { Authorization: `Basic ${credentials}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  getCardLayouts = async (id = 'InternalLayout') => {
    let credentials = Buffer.from(`${env.basicAuthentication.dtsUsername}:${env.basicAuthentication.dtsPassword}`).toString(
      'base64'
    )

    return await axios
      .get(`${env.urls.dtsURL}/configuration/all`, {
        headers: { Authorization: `Basic ${credentials}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  setCardLayouts = async layout => {
    let credentials = Buffer.from(`${env.basicAuthentication.dtsUsername}:${env.basicAuthentication.dtsPassword}`).toString(
      'base64'
    )

    return await axios.put(`${env.urls.dtsURL}/configuration`, layout, {
      headers: { Authorization: `Basic ${credentials}` },
      timeout: 30000
    })
  }

  deleteCardLayouts = async layoutId => {
    let credentials = Buffer.from(`${env.basicAuthentication.dtsUsername}:${env.basicAuthentication.dtsPassword}`).toString(
      'base64'
    )

    return await axios.delete(`${env.urls.dtsURL}/configuration/${layoutId}`, {
      headers: { Authorization: `Basic ${credentials}` },
      timeout: 30000
    })
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new DtsReportsApi()
  }
  return instance
}

export default getInstance()
