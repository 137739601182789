import { SlideUpDialog, PrimaryButton } from '@wavetronix/common-components'
import { TextField, DialogContent } from '@mui/material'
import { useState } from 'react'

export default function DateFilterDialog({ open, onClose, reportDataDateFilter, setReportDataDateFilter }) {
  const [tempDateValue, setTempDateValue] = useState(reportDataDateFilter.dayCount)
  return (
    <SlideUpDialog
      maxWidth='sm'
      open={open}
      onClose={() => {
        setTempDateValue(reportDataDateFilter.dayCount)
        onClose()
      }}
      actions={
        <>
          <PrimaryButton
            onClick={() => {
              setReportDataDateFilter({ open: false, dayCount: tempDateValue })
            }}
          >
            Save and fetch new data
          </PrimaryButton>
        </>
      }
    >
      <DialogContent>
        <TextField
          value={tempDateValue}
          onChange={e => setTempDateValue(e.target.value)}
          label={'Report days to fetch'}
          size='small'
          type='number'
        />
      </DialogContent>
    </SlideUpDialog>
  )
}
