export function versionComparator(a, b) {
  let aList = a.split('.')
  let bList = b.split('.')

  if (aList.length !== 4 || bList.length !== 4) {
    return 0
  } else if (aList[3] === bList[3]) {
    return 0
  } else {
    //really only need to check the build number
    return aList[3] > bList[3] ? 1 : -1
  }
}

export function multiVersionComparator(a, b, printInfo = false) {
  //a - <product>PRODUCT<version>VERSION...ETC.
  let aList = a.split('VERSION')
  let bList = b.split('VERSION')

  let index = 0
  let res = 0
  let unmatched = false
  while (index < aList.length && index < bList.length && res === 0 && unmatched === false) {
    // ONLY SEND TO BE COMPARED IF PRODUCTS ARE THE SAME ELSE RETURN 0
    let aProduct = aList[index].split('PRODUCT')[0]
    let bProduct = bList[index].split('PRODUCT')[0]

    if (aProduct !== bProduct) {
      unmatched = true
    }

    res = versionComparator(aList[index], bList[index])
    index += 1
  }

  return res
}
