import React from 'react'
import PageSwitcher from './PageSwitcher'

export default function MainPage() {
  return (
    <div>
      <PageSwitcher isLoginRequired={true} />
    </div>
  )
}
