import { useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import React, { useState, useMemo } from 'react'
import { BrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom'
import Dashboard from './Dashboard'
import Overview from './Overview'
import TestMetrics from './TestMetrics'
import ProductMetrics from './ProductMetrics'
import DtsReportsApi from '../api/DtsReportsApi'
import { NotFoundPage } from '@wavetronix/common-components'
import DTSAppBar from './DTSAppBar'
import DateFilterDialog from './dialogs/DateFilterDialog'

export default function PageSwitcher({ isLoginRequired }) {
  const { instance, accounts } = useMsal()
  const [reportDataDateFilter, setReportDataDateFilter] = useState({ open: false, dayCount: 45 })
  const [currentCabinet, setCurrentCabinet] = useState('')
  const [errorModalVisible, setErrorModalVisible] = useState(false)
  const [errorExists, setErrorExists] = useState(false)
  const [reports, setReports] = useState([])

  const { data: allTests, isLoading: allTestsLoading } = useQuery({
    queryKey: ['alltests', reportDataDateFilter.dayCount],
    queryFn: async () => {
      let res = await DtsReportsApi.getAll(isLoginRequired, instance, accounts, reportDataDateFilter.dayCount)
      return res
    },
    enabled: reportDataDateFilter.dayCount && reportDataDateFilter.dayCount > 0
  })

  const {
    data: reportsData,
    isLoading: reportsDataLoading,
    refetch: reportsRefetch
  } = useQuery({
    queryKey: ['reportsData', reportDataDateFilter.dayCount],
    queryFn: async () => await DtsReportsApi.getCurrent(isLoginRequired, instance, accounts, reportDataDateFilter.dayCount),
    enabled: reportDataDateFilter.dayCount && reportDataDateFilter.dayCount > 0
  })

  const sortedCabinetNames = useMemo(() => {
    if (reportsData) {
      let cabinetNames = reportsData.cabinetOverviews.map(c => c.cabinetName)
      let sorted = cabinetNames.sort()
      return sorted
    }
  }, [reportsData])

  function handleCabinetChange(e) {
    setReports([])
    setCurrentCabinet(e.target.value)
  }

  return (
    <div>
      <DateFilterDialog
        open={reportDataDateFilter.open}
        onClose={() => setReportDataDateFilter(f => ({ ...f, open: false }))}
        reportDataDateFilter={reportDataDateFilter}
        setReportDataDateFilter={setReportDataDateFilter}
      />
      <BrowserRouter>
        <Routes>
          <Route path='/' exact element={<Navigate to='/overview' replace />} />
          <Route
            element={
              <div>
                <DTSAppBar
                  currentCabinet={currentCabinet}
                  handleCabinetChange={handleCabinetChange}
                  sortedCabinetNames={sortedCabinetNames}
                  setErrorModalVisible={setErrorModalVisible}
                  errorExists={errorExists}
                  setReportDataDateFilter={setReportDataDateFilter}
                />
                <div>
                  <Outlet />
                </div>
              </div>
            }
          >
            <Route
              path='/overview'
              element={
                <Overview
                  isLoginRequired={isLoginRequired}
                  reportsData={reportsData}
                  reportsDataLoading={reportsDataLoading}
                  reportsRefetch={reportsRefetch}
                />
              }
            />
            <Route
              path='/product'
              element={
                <Dashboard
                  isLoginRequired={isLoginRequired}
                  currentCabinet={currentCabinet}
                  setCurrentCabinet={setCurrentCabinet}
                  reportsData={reportsData}
                  reportsDataLoading={reportsDataLoading}
                  reportsRefetch={reportsRefetch}
                  errorModalVisible={errorModalVisible}
                  setErrorModalVisible={setErrorModalVisible}
                  setErrorExists={setErrorExists}
                  reports={reports}
                  setReports={setReports}
                  daysFrom={reportDataDateFilter.dayCount}
                />
              }
            />
            <Route
              path='/testmetrics'
              element={<TestMetrics isLoginRequired={isLoginRequired} allTests={allTests} allTestsLoading={allTestsLoading} />}
            />
            <Route
              path='/productmetrics'
              element={<ProductMetrics isLoginRequired={isLoginRequired} allTests={allTests} allTestsLoading={allTestsLoading} />}
            />
          </Route>

          {/* This wildcard route needs to stay at the bottom */}
          <Route path='*' component={NotFoundPage} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}
