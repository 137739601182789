import React from 'react'
import { formatDate, formatTime } from '../utils/dateUtils.js'

export default function FailedTestDetails({ report }) {
  return (
    <div>
      <div style={{ marginTop: '20px' }}></div>
      <div className='details'>
        <span style={{ marginRight: '10px', fontWeight: 'bold', display: 'inline-block', width: '100px' }}>{'Branch:'}</span>
        <span style={{ fontFamily: 'Source Code Pro' }}>{`${report.Branch}`}</span>
      </div>
      <div className='details'>
        <span style={{ marginRight: '10px', fontWeight: 'bold', display: 'inline-block', width: '100px' }}>{'Start Time:'}</span>
        <span style={{ fontFamily: 'Source Code Pro' }}>{`${formatDate(report.StartTime)}`}</span>
        <span style={{ marginLeft: '20px', fontFamily: 'Source Code Pro' }}>{`${formatTime(report.StartTime)}`}</span>
      </div>
      <div className='details'>
        <span style={{ marginRight: '10px', fontWeight: 'bold', display: 'inline-block', width: '100px' }}>{'Machine:'}</span>
        <span style={{ fontFamily: 'Source Code Pro' }}>{`${report.MachineName}`}</span>
      </div>
    </div>
  )
}
