import { PieChart, Sector, Pie, Cell, ResponsiveContainer } from 'recharts'
import { WtxColors } from '@wavetronix/common-components'
import { Stack } from '@mui/material'

const renderCustomizedLabel = (props: any) => {
  const RADIAN = Math.PI / 180
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill='#333'>{`${payload.name} - ${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill='#999'>
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  )
}

export default function CirclePassFailGraph({ data, testCount }) {
  return (
    <>
      <ResponsiveContainer height={275} width={'100%'}>
        <PieChart width={800} height={200}>
          <Pie
            label={renderCustomizedLabel}
            data={data}
            innerRadius={60}
            outerRadius={80}
            fill='#8884d8'
            paddingAngle={2}
            dataKey='value'>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.name === 'Passed' ? WtxColors.GROWTH_GREEN : WtxColors.INNOVATION_RED} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Stack sx={{ width: '100%', alignItems: 'center', paddingBottom: '15px' }} spacing={2}>
        <h4>Total Pass/Fail Rate</h4>
        <div>Total Amount: {testCount}</div>
      </Stack>
    </>
  )
}
